<template>
  <div class="container text-center my-5">
    <h1>Game Forecasts</h1>

    <!-- Dates -->
    <div>
      <div class="sf-text-polygon">
        <!-- Start date field -->
        <input
          v-model="form.startDate"
          type="date"
          class="transparent-input"
          aria-describedby="basic-addon"
        />
      </div>
      <span> to </span>

      <!-- End date field -->
      <div class="sf-text-polygon">
        <input
          v-model="form.endDate"
          type="date"
          class="transparent-input"
          aria-describedby="basic-addon"
        />
      </div>
    </div>

    <!-- Search button -->
    <div class="row justify-content-center my-4">
      <div class="col-md-2">
        <button class="btn btn-primary btn-lg" :disabled="!canSearch" @click="search()">
          <i
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></i>
          <i v-else class="bi bi-search"></i>
          SEARCH GAMES
        </button>
      </div>
    </div>

    <!-- Results -->
    <div class="container mt-5" v-for="(games, date) in gamesGrouped" :key="date">
      <p class="event-time">
        {{ $dayjs(date).format("dddd, MMMM DD, YYYY") }}
      </p>

      <!-- Cards -->
      <div class="grid">
        <div v-for="game in games" :key="game.id" class="sf-card">
          <div class="flip-card-inner">
            <div class="flip-card-front container2" style="position: relative">
              <div class="Date">
                <div class="date">
                  {{
                    $dayjs(`${game["event-date"]} ${game["event-time"]}`).format("h:mm A")
                  }}
                  {{ timezone }}
                </div>
              </div>
              <div class="HOME-TEAM">
                <img
                  class="teamlogo"
                  :src="`https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${game['home-team']['team-key']}.png`"
                />
              </div>
              <div class="AWAY-TEAM">
                <img
                  class="teamlogo"
                  :src="`https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${game['away-team']['team-key']}.png`"
                />
              </div>
              <div class="VS"><div class="atsign">@</div></div>
              <div class="HOME-TEAM-NAME">
                <div class="">
                  <span class="team-place">{{ game["home-team"].name }}</span>
                </div>
              </div>
              <div class="AWAY-TEAM-NAME">
                <div class="">
                  <span class="team-place">{{ game["away-team"].name }}</span>
                </div>
              </div>
              <div class="SCORE">
                <span class="text-muted">Forecast</span><br /><span class="forecast">{{
                  round2decimals(game["home-team"]["guessing-score"])
                }}</span>
              </div>
              <div class="JSON"></div>
              <div class="SCORE2">
                <span class="forecast">
                  <span class="text-muted">Forecast</span>
                  <br />
                  {{ round2decimals(game["away-team"]["guessing-score"]) }}
                </span>
              </div>
              <div class="Divider"><hr class="hr-divider" /></div>
              <div class="BTN">
                <div
                  style="
                    display: flex;
                    gap: 1rem;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <router-link
                    class="btn btn-primary spofo_pro_button"
                    style="padding: 8px; font-size: 15px"
                    :to="{ name: 'details', params: { key: game['event-key'] } }"
                  >
                    Detailed Forecast
                  </router-link>
                  <a target="_blank" :href="`${scheduleEndpoint}/${game['event-key']}`">
                    <img :src="require('../assets/json-file.svg')" style="width: 25px" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import events from "./../../tests/fixtures/events.json";
import _ from "lodash";
import { mapGetters } from "vuex";
import { UPDATE_FILTERS } from "./../store/actions";

export default {
  name: "GameList",
  title: "Game Forecast Prototype",

  data() {
    return {
      isLoading: false,
      endpoint: "https://formula.forecaster.ca/api/schedules",
      scheduleEndpoint: "https://formula.forecaster.ca/api/schedule",
      gamesGrouped: [],
      gamesFromFile: events,
      timezone: "ET",
      form: {
        seasonId: 2021,
        startDate: "",
        endDate: "",
      },
    };
  },

  created() {
    document.title = this.$options.title;
  },

  mounted() {
    this.form.startDate = this.startDate;
    this.form.endDate = this.endDate;

    if (this.form.startDate === "") {
      this.form.startDate = this.getStartDate();
      this.form.endDate = this.getEndDate();
    }
    this.search();

    // Load games from file for tests purposes.
    // this.gamesGrouped = this.groupedByDate(this.gamesFromFile.events);
  },

  computed: {
    ...mapGetters(["startDate", "endDate"]),
    canSearch() {
      return this.form.startDate !== "" && this.form.endDate !== "";
    },
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.$store.dispatch(UPDATE_FILTERS, {
          filters: this.form,
        });
      },
    },
  },

  methods: {
    getStartDate() {
      return this.$dayjs().format("YYYY-MM-DD");
    },

    getEndDate() {
      return this.$dayjs().add(5, "day").format("YYYY-MM-DD");
    },

    search() {
      if (!this.canSearch) {
        return;
      }

      this.isLoading = true;
      axios({
        method: "GET",
        url: this.endpoint,
        params: {
          "season-id": this.form.seasonId,
          start_date: `${this.form.startDate} 00:00`,
          end_date: `${this.form.endDate} 24:00`,
        },
      })
        .then(
          (r) =>
            (this.gamesGrouped = this.groupedByDate(
              _.sortBy(r.data.events, "event-time")
            ))
        )
        .finally(() => {
          this.isLoading = false;
        });
    },

    groupedByDate(events) {
      return _.groupBy(_.sortBy(events, "event-date"), "event-date");
    },

    // TODO move this function to a global place, mixings or some such.
    round2decimals(num) {
      return parseFloat(num).toFixed(2);
    },
  },
};
</script>
